/**
 * Template page for a Product Category
 *
 */

// IMPORTS
import React from "react";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {graphql} from "gatsby";
import moment from "moment";
import {
  SEO,
  GlobalStyle,
  DescriptionColumns,
  NavigationHeader,
  ConsentBanner,
  ProductHeader,
  ProductIntro,
  ProductDescriptionComp,
  ContactListWithQuery,
  LinksListItemsComp,
} from "../components";
import {
  elementSizes,
  ProductPageResponseProps,
  DflRegionType,
  NavigationOverlayStateType,
  navigationOverlayStates,
  colors,
  ElementWrapper,
  MobileVisualWrapper,
  FullWidthImage,
  DesktopIntro,
  MobileIntro,
  MobileCaption,
  MobileTitle,
  MobileIntroText,
  MainContentWrapper,
  FooterWrapper,
  contentTypes,
  layouts,
  DescriptionColumnProps,
  threeColumnContentTypes,
} from "../models";
import {
  getDflRegionByTimezone,
  createRelatedProductsProps,
  // categorySlugFromUrl,
} from "../util/util";
import {trackModuleImpression} from "../util/tracking";

import {AppState} from "../store/";
import {
  setTimezoneDetectedAction,
  updateRegionAction,
  updateIsNavigationHiddenAction,
  updateNavigationOverlayAction,
  updateIsNavigationInvertedAction,
  updateIsHomepageAboveVideoAction,
  updateUTCOffsetAction,
} from "../store/system/actions";

// COMPONENT PROPERTIES
interface PropsFromState {
  selectedRegion: DflRegionType;
  timezoneDetected: boolean;
  isNavigationHidden: boolean;
  isTextFilterActive: boolean;
  currentJwPlayerId: string;
  screenWidth: number;
}
const mapStateToProps = (state: AppState): PropsFromState => ({
  selectedRegion: state.system.selectedRegion,
  timezoneDetected: state.system.timezoneDetected,
  isNavigationHidden: state.system.isNavigationHidden,
  isTextFilterActive: state.system.isTextFilterActive,
  currentJwPlayerId: state.system.currentJwPlayerId,
  screenWidth: state.system.screenWidth,
});

interface PropsFromDispatch {
  updateRegion: (region: DflRegionType) => void;
  setTimezoneDetected: () => void;
  updateUTCOffset: (newOffset: number) => void;
  updateIsNavigationHidden: (isHidden: boolean) => void;
  updateNavigationOverlay: (newState: NavigationOverlayStateType) => void;
  updateNavigationInverted: (isInverted: boolean) => void;
  updateIsHomepageAboveVideo: (isInverted: boolean) => void;
}
const mapDispatchToProps = (dispatch: Dispatch): PropsFromDispatch => {
  return {
    updateRegion: (region: DflRegionType) => {
      return dispatch(updateRegionAction(region));
    },
    setTimezoneDetected: () => {
      return dispatch(setTimezoneDetectedAction());
    },
    updateUTCOffset: (newOffset: number) => {
      return dispatch(updateUTCOffsetAction(newOffset));
    },
    updateIsNavigationHidden: (isHidden: boolean) => {
      return dispatch(updateIsNavigationHiddenAction(isHidden));
    },
    updateNavigationOverlay: (newState: NavigationOverlayStateType) => {
      return dispatch(updateNavigationOverlayAction(newState));
    },
    updateNavigationInverted: (isInverted: boolean) => {
      return dispatch(updateIsNavigationInvertedAction(isInverted));
    },
    updateIsHomepageAboveVideo: (isInverted: boolean) => {
      return dispatch(updateIsHomepageAboveVideoAction(isInverted));
    },
  };
};

// COMPONENT
interface ProductTemplateProps extends PropsFromState, PropsFromDispatch {
  data: ProductPageResponseProps;
}
class ProductTemplate extends React.Component<ProductTemplateProps> {
  constructor(props: ProductTemplateProps) {
    super(props);
    this.handleWheel = this.handleWheel.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const {currentJwPlayerId} = this.props;
    const nextJwPlayerId = nextProps.currentJwPlayerId;
    if (currentJwPlayerId !== null && nextJwPlayerId !== null && currentJwPlayerId !== nextJwPlayerId) {
      window.jwplayer(currentJwPlayerId).pause();
    }
  }
  // Product category page initialization
  componentDidMount() {
    // get Timezone and utc offset once
    if (!this.props.timezoneDetected) {
      const dflRegion = getDflRegionByTimezone();
      this.props.updateRegion(dflRegion);
      if (moment && moment().utcOffset() !== null) {
        // ignore daylight savings time
        const isDayLightSavings = moment().isDST();
        const dayLightSavingsOffset = isDayLightSavings ? 1 : 0;
        this.props.updateUTCOffset(moment().utcOffset() / 60 - dayLightSavingsOffset);
      }
      this.props.setTimezoneDetected();
    }

    // set navigation to black (could be inverted, coming from landing page)
    this.props.updateNavigationInverted(false);
    this.props.updateIsHomepageAboveVideo(false);

    // unhide the menu if it is hidden
    this.props.updateIsNavigationHidden(false);

    // hide overlay
    this.props.updateNavigationOverlay(navigationOverlayStates.default);

    // a fix for a bug in some browser versions not following an anchor link to the right position
    if (window.location.hash) {
      setTimeout(function() {
        const hash = window.location.hash.replace("#", "");
        const element = document.getElementById(hash);

        if (element) {
          const parent = element.parentElement;

          window.scrollTo({
            top: parent.offsetTop + element.offsetTop,
            behavior: "auto",
          });
        }
      }, 100);
    }
  }

  render() {
    const {data, selectedRegion, screenWidth} = this.props;

    const {
      title,
      intro,
      categorypage__products: pageProducts,
    } = data.wpPage;

    const description = data.wpPage.category.description;

    const {
      productList,
      related: relatedProductList,
    } = pageProducts;

    // get an array of product wordpress_ids
    const productListIds = productList ? productList.map(product => product.databaseId) : [];
    // from all posts, filter those whose wordpress_id is in the product list
    const productsToShow = productListIds.map(id =>
      data.allWpPost.edges.find(edge => edge.node.databaseId === id),
    );

    // get an array of related product wordpress_ids
    const relatedProductIds = relatedProductList ? relatedProductList.map(product => product.databaseId) : [];
    // from all posts, filter those whose wordpress_id is in the related products list
    const relatedProducts = relatedProductIds.map(id =>
      data.allWpPost.edges.find(edge => edge.node.databaseId === id),
    );

    // CREATE THE RELATED PRODUCTS PROPERTIES:
    const relatedProductsProps = createRelatedProductsProps(relatedProducts);
    trackModuleImpression(title);

    return (
      <div onWheel={this.handleWheel}>
        <GlobalStyle />
        {/* @ts-ignore */}
        <NavigationHeader isTextDark />
        <ConsentBanner />
        <SEO title={title} />

        <MainContentWrapper>
          {/* INTRO */}
          <DesktopIntro>
            <ElementWrapper style={{marginTop: elementSizes.productPageMarginTop}}>
              <ProductHeader caption={title} title={description} />
            </ElementWrapper>

            <ElementWrapper style={{marginTop: elementSizes.productPageMarginTop}}>
              <ProductIntro
                imageSize={intro.introImageSize}
                text={intro.introText}
                image={intro.introImage ? intro.introImage.sourceUrl : null}
              />
            </ElementWrapper>
          </DesktopIntro>

          <MobileIntro>
            <MobileVisualWrapper visualWidth={screenWidth}>
              <FullWidthImage src={intro.introImage.sourceUrl} />
            </MobileVisualWrapper>

            <MobileCaption>{title}</MobileCaption>
            <MobileTitle>{description}</MobileTitle>
            <MobileIntroText>{intro.introText}</MobileIntroText>
          </MobileIntro>

          {productsToShow.map((product, productIndex) => {
            const {
              productContentType: type,
              productVideoGroup: videoGroup,
              productImageGroup: imageGroup,
              productImageOrVideoSettings: settings,
              productThreeColumnModule: threeColumn,
              productButton,
            } = product.node.product_details.productContent;

            // IMAGE OR VIDEO
            if (type === contentTypes.video || type === contentTypes.image) {
              const localizedImages = imageGroup.localizedImageGroup;

              const localizedVideos = videoGroup.localizedVideoGroup;
              const {
                layout,
                productImageOrVideoHeadline: headline,
                videoSize,
                imageSize,
                decoration,
                attributes,
              } = settings;

              const videoId = videoGroup.videoId;

              const isLocalized =
                type === contentTypes.image
                  ? imageGroup.isImageLocalised
                  : type === contentTypes.video
                  ? videoGroup.isVideoLocalised
                  : null;

              return (
                <ElementWrapper key={productIndex}>
                  <ProductDescriptionComp
                    selectedRegion={selectedRegion}
                    screenWidth={screenWidth}
                    layout={layout === layouts.left ? "imageLeft" : "imageRight"}
                    contentType={type}
                    videoSize={videoSize}
                    decoration={decoration}
                    button={productButton}
                    imageSize={imageSize}
                    isLocalized={isLocalized}
                    imageUrl={imageGroup.image && imageGroup.image.sourceUrl}
                    imageAltText={imageGroup.image && imageGroup.image.altText}
                    localizedImages={localizedImages}
                    localizedVideos={localizedVideos}
                    title={headline}
                    descriptions={attributes.map(attribute => attribute.attribute)}
                    videoId={videoId}
                    videoPlaylistUrl={null}
                  />
                </ElementWrapper>
              );
            }

            // THREE COLUMN MODULE
            if (type === contentTypes.threeColumn) {
              const {
                threeColumnLocalizedContentColumnOne: columnOne,
                threeColumnLocalizedContentColumnTwo: columnTwo,
                threeColumnLocalizedContentColumnThree: columnThree,
                threeColumnLocalizedContentIsWithHeadline: isWithHeadline,
                threeColumnLocalizedContentHeadlineGroup: headlineGroup,
                threeColumnLocalizedContentIsWithLocalizedContent: isWithLocalizedContent,
                threeColumnLocalizedContentLocalizedContent: localizedContent,
              } = threeColumn;
              const columnContents: DescriptionColumnProps[] = [columnOne, columnTwo, columnThree].map(column => {
                return {
                  title: column.headline,
                  descriptions: column.attributes.map(attribute => attribute.attribute),
                };
              });
              const {contentType, localizedImage, localizedVideo} = localizedContent;

              return (
                <ElementWrapper key={productIndex}>
                  <DescriptionColumns
                    columns={columnContents}
                    cta={productButton}
                    headline={isWithHeadline && headlineGroup}
                    isLocalized={isWithLocalizedContent}
                    isImage={contentType === threeColumnContentTypes.image}
                    isVideo={contentType === threeColumnContentTypes.video}
                    localizedVideos={localizedVideo}
                    localizedImage={localizedImage}
                    selectedRegion={selectedRegion}
                  />
                </ElementWrapper>
              );
            }
          })}

          <ElementWrapper
            style={{marginTop: elementSizes.productPageMarginTop, padding: "60px 22px"}}
            bgColor={colors.grayDarkLight}
            hasPadding
          >
            <LinksListItemsComp content={relatedProductsProps} />
          </ElementWrapper>

          <FooterWrapper>
            <ContactListWithQuery />
          </FooterWrapper>
        </MainContentWrapper>
      </div>
    );
  }

  handleWheel(event: React.WheelEvent) {
    const {isNavigationHidden, updateIsNavigationHidden, isTextFilterActive} = this.props;
    if (event.deltaY > 0) {
      if (!isNavigationHidden && !isTextFilterActive) {
        updateIsNavigationHidden(true);
      }
    } else if (event.deltaY < 0) {
      if (isNavigationHidden) {
        updateIsNavigationHidden(false);
      }
    }
  }
}

export const query = graphql`
  query($id: Int!) {
    wpPage(databaseId: {eq: $id}) {
      ...pageInfoFragment
    }
    allWpPost(filter: {databaseId: {nin: [1272, 1]}}) {
      edges {
        node {
          product_details {
            productContent {
              productCategory
              productContentType
              productImageOrVideoSettings {
                decoration
                imageSize
                layout
                videoSize
                productImageOrVideoHeadline
                attributes {
                  attribute
                }
              }
              productImageGroup {
                isImageLocalised
                localizedImageGroup {
                  productLocalizedImageImageEmea {
                    altText
                    sourceUrl
                  }
                  productLocalizedImageImageAmericas {
                    altText
                    sourceUrl
                  }
                  productLocalizedImageImageAsia {
                    altText
                    sourceUrl
                  }
                }
                image {
                  altText
                  sourceUrl
                }
              }
              productVideoGroup {
                isVideoLocalised
                videoId
                localizedVideoGroup {
                  videoEmeaId
                  videoAmericasId
                  videoAsiaId
                }
              }
              productThreeColumnModule {
                overallTitle
                threeColumnLocalizedContentColumnOne {
                  headline
                  attributes {
                    attribute
                  }
                }
                threeColumnLocalizedContentColumnTwo {
                  headline
                  attributes {
                    attribute
                  }
                }
                threeColumnLocalizedContentColumnThree {
                  headline
                  attributes {
                    attribute
                  }
                }
                threeColumnLocalizedContentIsWithHeadline
                threeColumnLocalizedContentHeadlineGroup {
                  headline
                  subheadline
                }
                threeColumnLocalizedContentIsWithLocalizedContent
                threeColumnLocalizedContentLocalizedContent {
                  contentType
                  localizedImage {
                    imageEmea {
                      altText
                      sourceUrl
                    }
                    imageAmericas {
                      altText
                      sourceUrl
                    }
                    imageAsia {
                      altText
                      sourceUrl
                    }
                  }
                  localizedVideo {
                    videoEmeaId
                    videoAmericasId
                    videoAsiaId
                  }
                }
              }
              productButton {
                label
                link
              }
            }
          }
          databaseId
        }
      }
    }
  }
`;
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductTemplate);
